<template>
  <div v-loading="loading" class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form">
      <eden-form-section>
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap mb-0">
            <el-col :md="24" class="is-flex is-flex-column is-align-center">
              <eden-image-upload
                :image-url.sync="form.avatar"
                :image-text="'avatar'"
                :image-folder="'logistics/riders'"
              />
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Rider Information"
        subtitle="Please provide this rider’s information"
      >
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap">
            <el-col :md="12">
              <el-form-item
                label="First name"
                prop="firstname"
                :rules="validateName()"
              >
                <el-input
                  type="text"
                  v-model="form.firstname"
                  placeholder="Enter first name"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item
                label="Last name"
                prop="lastname"
                :rules="validateName()"
              >
                <el-input
                  type="text"
                  v-model="form.lastname"
                  placeholder="Enter last name"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :md="24">
              <el-form-item
                label="Phone number"
                prop="phone_number"
                :rules="validatePhone()"
              >
                <eden-phone-input :input.sync="form.phone_number" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :md="24">
              <el-form-item
                label="Company"
                prop="company_id"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.company_id"
                  filterable
                  placeholder="Select Company"
                >
                  <el-option
                    v-for="company in companies"
                    :key="company.id"
                    :label="company.name"
                    :value="company.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Services"
                prop="services"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.services"
                  multiple
                  placeholder="Select services"
                >
                  <el-option value="laundry">Laundry </el-option>
                  <el-option value="meal">Meal</el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Location areas"
                prop="location_area_ids"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.location_area_ids"
                  filterable
                  multiple
                  placeholder="What location areas will they be delivering to?"
                >
                  <el-option
                    v-for="area in locationareas"
                    :key="area.id"
                    :label="area.name"
                    :value="area.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section title="" subtitle="">
        <template slot="form-fields">
          <div class="eden-page-form__actions">
            <el-button plain @click="cancel">Cancel</el-button>
            <el-button
              v-if="action === 'add'"
              type="primary"
              :loading="adding"
              @click="add"
              >Add Rider
            </el-button>
            <el-button v-else type="primary" :loading="updating" @click="update"
              >Save changes
            </el-button>
          </div>
        </template>
      </eden-form-section>
    </el-form>
  </div>
</template>

<script>
import companies from "@/requests/logistics/directory/companies";
import riders from "@/requests/logistics/directory/riders";

export default {
  name: "RidersForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    rider: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      uploadingAvatar: false,
      form: {
        avatar: "",
        firstname: "",
        lastname: "",
        phone_number: "",
        company_id: "",
        services: [],
        location_area_ids: [],
      },
      companies: [],
      services: ["meals", "laundry"],
      adding: false,
      updating: false,
    };
  },
  computed: {
    locationareas() {
      return this.$store.getters.location_areas_list;
    },
  },
  created() {
    this.getCompanies();
    if (this.action === "edit") {
      this.loading = true;
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.rider[key];
      });
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  },
  methods: {
    getCompanies() {
      companies
        .index(1)
        .then((response) => {
          if (response.data.status) {
            this.companies = response.data.data;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    cancel() {
      this.$router.push({
        name: "directory.index",
        query: { t: "riders" },
      });
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        riders
          .add(this.form)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.$message.success(message);
              this.$router.push({
                name: "logistics.directory.index",
                query: { t: "riders" },
              });
            }
            this.adding = false;
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        const payload = {
          rider_id: this.rider.id,
          ...this.form,
        };
        this.updating = true;
        riders
          .update(payload)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.$message.success(message);
              this.$router.push({
                name: "logistics.directory.rider",
                params: { id: this.rider.id },
              });
            }
            this.updating = false;
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
