<template>
  <div>
    <eden-page-header
      :title="'Rider Directory'"
      :subtitle="'Edit Rider Profile'"
    />
    <rider-form v-if="rider.id" :action="'edit'" :rider="rider" />
  </div>
</template>

<script>
import RiderForm from "@/components/Logistics/Directory/Riders/RiderForm.vue";
import riders from "@/requests/logistics/directory/riders";
import * as actions from "@/store/action-types";

export default {
  name: "RiderEdit",
  components: { RiderForm },
  data() {
    return {
      rider: {},
    };
  },
  computed: {
    riderId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
    this.getRider();
  },
  methods: {
    getRider() {
      riders
        .get(this.riderId)
        .then((response) => {
          if (response.data.status) {
            this.rider = response.data.data[0];
            this.rider.company_id = this.rider.company.id;
            this.rider.location_area_ids = this.rider.location_areas.map(
              (area) => {
                return area.id;
              }
            );
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>
